<!--  -->
<template>
    <div>
        <h3>胎心监测</h3>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadBaby()">
                            <font-awesome-icon icon="fa-solid fa-person-breastfeeding" size="4x"
                                style="color:firebrick" />
                            <h1>胎心仪</h1>
                        </span>

                    </el-dropdown>
                </div>
            </el-col>
            <!-- <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadBio()">
                            <font-awesome-icon icon="fa-solid fa-flask" size="4x" style="color:firebrick" />
                            <h1>生化分析仪 </h1>
                        </span>

                    </el-dropdown>
                </div>
            </el-col> -->
        </el-row>
        <h3>关联检测</h3>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadSingo()">
                            <font-awesome-icon icon="fa-solid fa-heart-pulse" size="4x" style="color:firebrick" />
                            <h1>心电检测</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <!-- <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadSingoHolter()">
                            <font-awesome-icon icon="fa-solid fa-circle-h" size="4x" style="color:firebrick" />
                            <h1>24小时Holter </h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadEight()">
                            <font-awesome-icon icon="fa-solid fa-file-waveform" size="4x" style="color:firebrick" />
                            <h1>八导联 </h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadEightHolter()">
                            <font-awesome-icon icon="fa-solid fa-square-h" size="4x" style="color:firebrick" />
                            <h1>八导联Holter</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col> -->
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadUrine()">
                            <font-awesome-icon icon="fa-solid fa-vial" size="4x" style="color:firebrick" />
                            <h1>尿液分析仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadOxygen()">
                            <font-awesome-icon icon="fa-regular fa-circle" size="4x" style="color:firebrick" />
                            <h1>血氧检测仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadGlucose()">
                            <font-awesome-icon icon="fa-solid fa-hard-drive" size="4x" style="color:firebrick" />
                            <h1>血糖检测仪 </h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <!-- <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadUrine()">
                            <font-awesome-icon icon="fa-solid fa-vial" size="4x" style="color:firebrick" />
                            <h1>尿液分析仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadOxygen()">
                            <font-awesome-icon icon="fa-regular fa-circle" size="4x" style="color:firebrick" />
                            <h1>血氧检测仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadGlucose()">
                            <font-awesome-icon icon="fa-solid fa-hard-drive" size="4x" style="color:firebrick" />
                            <h1>血糖检测仪 </h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col> -->
            <!-- <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadFat()">
                            <font-awesome-icon icon="fa-solid fa-droplet" size="4x" style="color:firebrick" />
                            <h1>血脂检测仪 </h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col> -->
        </el-row>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadPressure()">
                            <font-awesome-icon icon="fa-solid fa-stethoscope" size="4x" style="color:firebrick" />
                            <h1>血压检测仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadBodyFat()">
                            <font-awesome-icon icon="fa-solid fa-weight-scale" size="4x" style="color:firebrick" />
                            <h1>体脂检测仪</h1>
                        </span>
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadTem()">
                            <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" size="4x"
                                style="color:firebrick" />
                            <h1>基础体温 </h1>
                        </span>

                    </el-dropdown>
                </div>
            </el-col>
            <!-- <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadThreeInOne()">
                            <font-awesome-icon icon="fa-solid fa-cubes" size="4x" style="color:firebrick" />
                            <h1>三合一检测仪</h1>
                        </span>

                    </el-dropdown>
                </div>
            </el-col> -->
        </el-row>

    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: { // 这里是考虑 从【亲情关怀】跳转到【个人数据】时，crossPlatformStatus需要恢复为false
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        },
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        ReadSingo() {
            this.$router.push({
                name: 'HistorySingo',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadSingoHolter() {
            this.$router.push({
                name: 'HistorySingoHolter',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadEight() {
            this.$router.push({
                name: 'HistoryEight',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadEightHolter() {
            this.$router.push({
                name: 'HistoryEightHolter',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadFat() {
            this.$router.push({
                name: 'HistoryBloodFat',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadGlucose() {
            this.$router.push({
                name: 'HistoryBloodGlucose',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadOxygen() {
            this.$router.push({
                name: 'HistoryBloodOxygen',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadPressure() {
            this.$router.push({
                name: 'HistoryBloodPressure',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadBaby() {
            this.$router.push({
                name: 'HistoryBaby',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadBio() {
            this.$router.push({
                name: 'HistoryBio',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadBodyFat() {
            this.$router.push({
                name: 'HistoryBodyFat',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadTem() {
            this.$router.push({
                name: 'HistoryTem',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadThreeInOne() {
            this.$router.push({
                name: 'HistoryThreeInOne',
                query: {
                    uid: this.uid,
                }
            })
        },
        ReadUrine() {
            this.$router.push({
                name: 'HistoryUrine',
                query: {
                    uid: this.uid,
                }
            })
        },

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.crossPlatformStatus = false   // 这里是考虑 从【亲情关怀】跳转到【个人数据】时，crossPlatformStatus需要恢复为false
        this.uid = this.$store.state.women_baby.uid
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
.service-box {
    display: inline-block;
    width: 300px;
    height: 145px;
    text-align: center;
}

.el-dropdown-link {
    cursor: pointer;
    color: #252525;
}

.dropmenu {
    width: 240px;
    height: 200px;
    padding: 20px 20px;
}

.sort-item {
    display: inline-block;
    min-width: 110px;
    padding: 10 10px;
    margin: 10px 0px 10px 0;
    border-radius: 16px;
    line-height: 60px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    color: dodgerblue
}
</style>